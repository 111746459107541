<template>
  <Container class="grid w-full gap-8 pt-4 pb-12 lg:pt-12 lg:pb-24 lg:grid-cols-2">
    <Accordion :items="accordionFirstHalf" /><Accordion :items="accordionSecondHalf" />
  </Container>
</template>
<script setup lang="ts">
import { computed } from 'vue';
interface accordionBlock {
  accordion: {
    title: string;
    content: string;
  }[];
}

const props = defineProps<accordionBlock>();

const half = Math.floor(props.accordion.length / 2);

const accordionFirstHalf = computed(() => props.accordion.slice(0, half));
const accordionSecondHalf = computed(() => props.accordion.slice(half, props.accordion.length));
</script>
